import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useState, useRef } from 'react';
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';
import logoBlue from '../../../assets/images/logo-blue.png';
import supportService from '../../../services/support.service';
import {
  SupportTicket,
  SupportTicketStatus,
} from '../../../models/supportTicket.model';

const DeleteAccount = () => {
  const msgs1 = useRef<any>(null);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [reason, setReason] = useState<string>('');
  const [completed, setCompleted] = useState<boolean>(false);

  const handleDeleteAccount = async () => {
    const ticket: SupportTicket = {
      status: SupportTicketStatus.PENDING,
      title: 'Delete Account Request',
      description: `Name: ${name}, Email: ${email}, Reason: ${reason}`,
      userId: 1,
    };

    const response = await supportService.create_support_ticket(ticket);
    setCompleted(true);

    msgs1.current.show({
      closable: false,
      severity: 'success',
      detail:
        'Request Created, your account will be deleted in less than 48hrs',
    });
  };

  return (
    <div className="page page__public">
      <div className="flex align-items-center justify-content-center mt-5">
        <div className="surface-card p-4 shadow-2 border-round w-full md:w-6">
          <div className="text-center mb-4">
            <img src={logoBlue} alt="MERA" height={50} className="mb-3" />
            <div className="text-900 text-3xl font-medium">
              Sorry to see you go
            </div>
            <div className="text-800 text-xl font-medium mb-3">
              Please fill the form below to delete your account
            </div>
            <Messages ref={msgs1} />
          </div>

          {!completed ? (
            <div>
              <label
                htmlFor="email"
                className="block text-900 font-medium mb-2"
              >
                Your Name
              </label>

              <InputText
                onBlur={(e) => setName(e.target.value)}
                id="name"
                type="text"
                className="w-full mb-3"
              />

              <label
                htmlFor="email"
                className="block text-900 font-medium mb-2"
              >
                Email
              </label>

              <InputText
                onBlur={(e) => setEmail(e.target.value)}
                id="email"
                type="text"
                className="w-full mb-3"
              />

              <label
                htmlFor="password"
                className="block text-900 font-medium mb-2"
              >
                Reason for deleting account
              </label>
              <InputTextarea
                onBlur={(e) => setReason(e.target.value)}
                id="reason"
                type="multiline"
                className="w-full mb-3"
              />

              <Button
                disabled={!email && !reason && !name}
                onClick={handleDeleteAccount}
                label="Delete My Account"
                className="w-full"
                // icon="pi pi-user"
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
