import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { memoizedRefreshToken } from './api.refreshtoken';

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  // access token is set on http only cookie, nothing to do here
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosError<any, any>): Promise<AxiosError> => {
  if (error.response) {
    if (error.response.status === 401 && error.response.data.message === 'jwt expired') {
      // Access Token was expired
      await memoizedRefreshToken();

      // retry the request
      return axios.request(error.config);
    }
  }
  return Promise.reject(error);
};

export const setupInterceptors = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
};
