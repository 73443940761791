import { useState, useEffect, useMemo } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { fbDb } from '../firebase';
import { Request } from '../../models/request.model';
import moment from 'moment';
import { RequestStatus } from '../../models/enums';
import { Subject } from 'rxjs';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../features/status';
import requestService from '../../services/request.service';
import { addActiveRequest, addBatchActiveRequests, updateActiveRequest, markRequestAsCompleted } from '../../features/activeRequests';



const useDashboardActiveRequests = () => {  
  const [initialized, setInitialized] = useState<boolean>(false);
  const dispatch = useDispatch();
  // const notificationSubject = useMemo(() => new Subject<ChangedRequestEvent>(), []);
  console.log("useDashboardActiveRequests");

  const getRequest = async () => {    
    dispatch(setLoading(true));
    const response = await requestService.getRequest();

    // move this to an store action
    if (response.status === 200) {
      const data = response.data.map((data: Request) => {
        return {
          ...data,
          lovedone: {
            ...data.lovedone,
            fullName: `${data?.lovedone?.firstName} ${data?.lovedone?.lastName}`,
          },
          responder: {
            ...data.responder,
            fullName: `${data?.responder?.firstName || ""} ${
              data?.responder?.lastName || ""
            }`,
          },
          createdAt: moment(data.createdAt).format("MM-DD-YYYY | HH:mm"),
        };
      });            
      dispatch(addBatchActiveRequests(data));
    } else {
      console.error(response);
    }

    dispatch(setLoading(false));
    setInitialized(true);
  };

  const formatFbDateForDisplay = (fbDate: any) => {
    return moment(fbDate).format('MM-DD-YYYY | HH:mm');
  };

  const prepareRequest = (request: Request) => {
    const copy = { ...request };
    copy.createdAt = formatFbDateForDisplay(copy.createdAt);
    copy.lovedone.fullName = `${copy.lovedone.firstName} ${copy.lovedone.lastName}`;

    if (copy.responder) {
      copy.responder.fullName = `${copy.responder.firstName} ${copy.responder.lastName}`;
    }

    return copy;
  };

  const notifyChange = (request: Request, change: string) => {
    // notificationSubject.next({ type: change, id: request.id });
  };


  useEffect(() => {
    getRequest();
  }, []);

  useEffect(() => {
    if (!fbDb || !initialized) return;

    const unsub = onSnapshot(collection(fbDb, 'active_requests'), (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        let data = change.doc.data() as Request;
        if (change.type === 'added') {
          dispatch(addActiveRequest(prepareRequest(data)));
        }
        if (change.type === 'modified') {
          dispatch(updateActiveRequest(prepareRequest(data)));
        }
        if (change.type === 'removed') {
          dispatch(markRequestAsCompleted(prepareRequest(data)));
        }
        notifyChange(data, change.type);
      });
    });
    return () => unsub();
  }, [fbDb, initialized]);

  // return { onRequestChange: notificationSubject.asObservable() };
};

export default useDashboardActiveRequests;
