import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./auth";
import statusReducer from "./status";
import activeRequests from "./activeRequests";
import locationReducer from "./location";

export const store = configureStore({
  reducer: {
    auth: userReducer,
    status: statusReducer,
    activeRequests: activeRequests,
    location: locationReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
