import { useEffect, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { GeoFence, Polygon } from '../../models/polygon';
import geofenceService from '../../services/geofence.service';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ToastType, showNextToast } from '../../utils/hooks/showToast';

interface AddPolygonDialogProps {
  isVisibleDialog: boolean;
  setIsVisibleDialog: (isVisibleDialog: boolean) => void;
  handleCloseDialog: () => void;
  polygon: Polygon;
  setPolygon: (polygon: Polygon) => void;
}

export const AddPolygonDialog = ({ isVisibleDialog, setIsVisibleDialog, handleCloseDialog, polygon, setPolygon }: AddPolygonDialogProps) => {
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (name === '') {
      return setIsEmpty(true);
    }

    try {
      setLoading(true);
      await saveGeoFence();
      setPolygon({ ...polygon, name: name });
    } catch (error) {
      console.log(error);
    } finally {
      setName('');
      handleCloseDialog();
      setLoading(false);
    }
  };

  const saveGeoFence = async () => {
    const geoFence: GeoFence = {
      name: name,
      polygon: {
        name: name,
        path: polygon.path,
      },
    };
    const res = await geofenceService.saveGeofence(geoFence);
    if (res.status === 201) {
      showNextToast(ToastType.SUCCESS, 'Success', 'GeoFence saved successfully');
    } else {
      showNextToast(ToastType.ERROR, 'Error', 'Error saving GeoFence');
      console.log(res);
    }
  };

  const onClose = () => {
    setIsEmpty(false);
    handleCloseDialog();
    setPolygon({ ...polygon, name: '' });
    setName('');
  };

  useEffect(() => {
    if (name !== '') {
      setIsEmpty(false);
    }
  }, [name]);

  const getBody = () => {
    if (loading) {
      return (
        <div className="mb-4 text-center">
          <ProgressSpinner />
          <p className="bt-1">Saving, please wait</p>
        </div>
      );
    }

    // form
    return (
      <div className="flex justify-content-center mb-4">
        <div className="field">
          <label htmlFor="areaName" className="block">
            Area Name
          </label>
          <InputText id="areaName" aria-describedby="areaName-help" className={`${isEmpty && 'p-invalid'}`} value={name} onChange={(e) => setName(e.target.value)} style={{ width: '300px' }} />
          {isEmpty && (
            <small id="areaName-help" className="p-error block">
              Area Name Required
            </small>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <Dialog header="Adding New Area" visible={isVisibleDialog} style={{ width: '25rem' }} onHide={() => handleCloseDialog()} closable={false}>
        <div>
          {getBody()}

          <div className="text-right">
            <Button disabled={loading} className="p-button-outlined p-button-secondary mr-3" label="Cancel" onClick={() => onClose()} />
            <Button disabled={loading} label="Save" onClick={() => handleSubmit()} />
          </div>
        </div>
      </Dialog>
    </>
  );
};
