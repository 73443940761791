import { createSlice } from '@reduxjs/toolkit';
import { StatusSliceType } from './types';

const initialState: StatusSliceType = {
    loading: false
};

const statusSlice = createSlice({
    name: "status",
    initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        }
    }
});

export const { setLoading } = statusSlice.actions;
export default statusSlice.reducer;