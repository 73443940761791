import React from "react";
import { useJsApiLoader, GoogleMap } from "@react-google-maps/api";
import { Map } from "../../components/map/Map";
import { Loader } from "../../components/loader/loader";

const Dashboard911 = () => {
  
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAxKqdxn5i_ACE5_Wl9k130371WGveiPXQ",
    language: "en",
    region: "us",
    libraries: ["drawing", "places"],
  });

  if (!isLoaded)
    return (
      <div className="page dashboard-page h-full flex flex-column align-items-center">
        <Loader />
      </div>
    );

  return (
    <>
      <Map />
    </>
  );
};

export default Dashboard911;
