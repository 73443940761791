import { createSlice } from "@reduxjs/toolkit";
import { LocationSliceType } from "./types";

const initialState: LocationSliceType = {
  lat: 44.899726,
  lng: -89.72133,
};

// reducers
const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLocationLatitude: (state, action) => {
      state.lat = action.payload;
    },
    setLocationLongitude: (state, action) => {
      state.lng = action.payload;
    },
  },
});

export const { setLocationLatitude, setLocationLongitude } =
  locationSlice.actions;
export default locationSlice.reducer;
