import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';


const firebaseConfig = {
  apiKey: 'AIzaSyDk7rZbk22SGzGYWO76DRFEFAdnCy5k6bo',
  authDomain: 'mera-62010.firebaseapp.com',
  projectId: 'mera-62010',
  storageBucket: 'mera-62010.appspot.com',
  messagingSenderId: '1067750542243',
  appId: '1:1067750542243:web:038ff79a7c5826b622a816',
  measurementId: 'G-NQZ2TFQHQ1',
};

export const firebaseApp = initializeApp(firebaseConfig);
export const fbDb = getFirestore(firebaseApp);
