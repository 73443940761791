import { Button } from "primereact/button";
import { useState, useEffect } from "react";
import playlistService from "../../services/playlist.service";
import { Divider } from "primereact/divider";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { triggerAsyncId } from "async_hooks";
const EditInfo = ({ id, playlist, handleEditPlaylist }: any) => {
  library.add(faPenToSquare);

  const [item, setItem] = useState<any>({
    id: 0,
    imageUrl: "",
    name: "",
    services: [{ id: 0, url: "", service: 0 }],
  });

  const [isDisabled, setIsDisable] = useState<boolean>(false);

  const [isVisible, setIsVisible] = useState<boolean>(true);

  const [prevVal, setPrevVal] = useState(item);

  const [onEdit, setOnEdit] = useState<boolean>(false);

  const serviceSelectItems = [
    { label: "Youtube", value: 4 },
    { label: "Spotify", value: 1 },
    { label: "SoundCloud", value: 3 },
    { label: "Tidal", value: 5 },
    { label: "Apple Music", value: 2 },
  ];

  const handleUpdate = async () => {
    let resp = await playlistService.updatePlaylist(
      prevVal.name,
      prevVal.imageUrl,
      prevVal.services,
      prevVal.id,
      id
    );
    if (resp.status === 200) {
      handleEditPlaylist();
    } else {
      console.log(resp.status, resp.data.error);
    }
  };

  const findPlaylist = () => {
    let item = playlist.filter((i: any) => i.id === id);
    setPrevVal(item[0]);
    setItem(item[0]);
  };

  const removeService = (idService: any) => {
    if (prevVal.services.length <= 5) {
      setIsVisible(true);
    }
    let updated = prevVal.services.filter((i: any) => i.id !== idService);
    setPrevVal({ ...prevVal, services: updated });
  };

  const addService = () => {
    if (prevVal.services.length >= 4) {
      setIsVisible(false);
    }
    let added = {
      id: Math.random() * 100,
      url: "",
      service: 0,
      playlist: prevVal.id,
    };
    setPrevVal({ ...prevVal, services: [...prevVal.services, added] });
  };

  const infoHandler = (index: any, e: any) => {
    let info = prevVal.services;
    info[index][e.target.name] = e.target.value;
    console.log(index);

    setPrevVal({ ...prevVal, services: info });
  };

  useEffect(() => {
    if (prevVal.services.length <= 0) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [prevVal.services.length]);

  const Editing = () => {
    if (onEdit) {
      return (
        <div
          className="d-flex justify-content-between flex-column"
          style={{ width: "100%" }}
        >
          <Button
            className="p-button-rounded"
            icon="pi pi-arrow-left"
            onClick={() => {
              setOnEdit(false);
            }}
            style={{ backgroundColor: "#488ccb" }}
          />
          <div className="m-3">
            <h5>Name</h5>

            <InputText
              value={prevVal.name}
              name="name"
              style={{ width: "75%" }}
              onChange={(e) => setPrevVal({ ...prevVal, name: e.target.value })}
            />
          </div>
          <div className="m-3">
            <h5>Image url</h5>

            <InputText
              value={prevVal.imageUrl}
              name="url"
              style={{ width: "75%" }}
              onChange={(e) =>
                setPrevVal({ ...prevVal, imageUrl: e.target.value })
              }
            />
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div
            className="d-flex justify-content-between"
            style={{ width: "100%" }}
          >
            <div>
              <h5>Name</h5>
              <p>{prevVal.name}</p>
            </div>
            <Button
              className="p-button-text p-button-rounded"
              onClick={() => setOnEdit(true)}
              icon="pi pi-pencil"
              style={{
                alignSelf: "center",
                justifyContent: "center",
                color: "#488ccb",
              }}
            >
              {/* <FontAwesomeIcon icon={["fas", "pen-to-square"]} /> */}
            </Button>
          </div>

          <Divider />
          <div
            className="d-flex justify-content-between"
            style={{ width: "100%" }}
          >
            <div>
              <h5>Image url</h5>
              <p>{prevVal.imageUrl}</p>
            </div>
            <Button
              className="p-button-text p-button-rounded"
              onClick={() => setOnEdit(true)}
              icon="pi pi-pencil"
              style={{
                alignSelf: "center",
                justifyContent: "center",
                color: "#488ccb",
              }}
            >
              {/* <FontAwesomeIcon icon={["fas", "pen-to-square"]} /> */}
            </Button>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    findPlaylist();
  }, []);

  useEffect(() => {
    console.log("Hello");
  }, [prevVal]);

  const serviceInput = () => (
    <div style={{ width: "100%" }}>
      <h5>Services</h5>
      {prevVal.services.map((item: any, index: any) => (
        <div
          key={index}
          className="d-flex flex-row  "
          style={{ width: "100%", margin: "1%" }}
        >
          <Dropdown
            options={serviceSelectItems}
            value={item.service}
            name="service"
            onChange={(e) => {
              infoHandler(index, e);
            }}
          />
          <InputText
            placeholder={item.url}
            value={item.url}
            name="url"
            style={{ width: "60%" }}
            onChange={(e) => infoHandler(index, e)}
          />
          <Button
            className="p-button-danger p-button-text "
            icon="pi pi-times"
            onClick={() => {
              removeService(item.id);
            }}
          />
        </div>
      ))}
    </div>
  );

  return (
    <div>
      <div>
        {Editing()}
        <Divider />
        <div
          className="d-flex justify-content-between"
          style={{ width: "100%" }}
        >
          {serviceInput()}
        </div>
      </div>
      <div className="flex justify-content-between mt-5">
        {isVisible ? (
          <Button
            className="p-button-outlined p-button-rounded "
            icon="pi pi-plus"
            style={{ color: "#488ccb" }}
            onClick={() => {
              addService();
            }}
          />
        ) : null}

        <Button
          label="Save"
          style={{ backgroundColor: "#488ccb" }}
          disabled={isDisabled}
          onClick={() => {
            handleUpdate();
          }}
        />
      </div>
    </div>
  );
};

export default EditInfo;
