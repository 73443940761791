export enum RequestStatus {
  NONE = 0,
  WAITING = 1,
  ACCEPTED = 2,
  COMPLETED = 3,
}

export enum EventType {
  LISTEN_AAPlaylist = 1,
  FEELING_GOOD = 2,
  FEELING_BAD = 3,
  REQUEST_CREATED = 4,
  REQUEST_ACCEPTED = 5,
  REQUEST_COMPLETED = 6,
  REQUEST_CANCELLED = 61,
  WANTS_TO_TALK = 7,
  APP_OPENED = 8,
  CALL_STARTED = 9,
  CHAT_STARTED = 10,
}

export enum UserType {
  UNKNOWN = 0,
  WEB = 1,
  LOVEDONE = 2,
  CARETAKER = 3,
  PROVIDER = 4,
}