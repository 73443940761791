import mem from "mem";
import LocalStorageService from "../services/localStorage.service";
import api from "./api";

const refreshTokenFn = async () => {
  const savedToken = LocalStorageService.getToken();

  try {
    const response = await api.post("/auth/token/refresh", {
      refreshToken: savedToken,
    });

    const { token } = response.data.token;
    LocalStorageService.setToken(token);   

    return response.data;
  } catch (error) {
    console.log("Error Refreshing token", error);
  }
};

const maxAge = 20000;
export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
});