import React from "react";
import PrimeReact from "primereact/api";
import PlaylistTable from "../../components/playlist/playlistTable";

const PlaylistPage = () => {
  return (
    <div className="page dashboard-page">

      <PlaylistTable />
    </div>
  );
};

export default PlaylistPage;
