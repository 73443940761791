import { User } from "../../models/user.model";

export enum LoggedInStatus {
    NOT_VERIFIED = 0, // first page load ?
    NOT_LOGGED_IN = 1,
    LOGGED_IN = 2
}

export interface UserSliceType {
    user: User | null;
    loginStatus: LoggedInStatus;
}