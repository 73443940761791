import api from "../utils/api";
import LocalStorageService from './localStorage.service';

class AuthService {
  async login(email: string, password: string) {
    let payload = {
      email: email,
      password: password,
    };

    return await api.post("/auth/login/", payload);
  }

  async register(
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    confirmPassword: string
  ) {
    let payload = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      confirmPassword: confirmPassword,
    };

    return await api.post("/auth/register/", payload);
  }

  async logout() {
    return await api.post('/auth/logout/', { refreshToken: LocalStorageService.getToken() });
  }

  async me() {
    return await api.get("/auth/me/");
  }
}

export default new AuthService();
