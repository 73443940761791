import { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import playlistService from "../../services/playlist.service";

const AddInfo = ({ value, setIsAddInfoVisible, handleNewPlaylist }: any) => {
  const [playlist, setPlaylist] = useState<any>([]);
  const [isVisible, setIsVisible] = useState(true);
  const [isDisabled, setIsDisable] = useState<boolean>(false);

  const serviceSelectItems = [
    { label: "Youtube", value: 4 },
    { label: "Spotify", value: 1 },
    { label: "SoundCloud", value: 3 },
    { label: "Tidal", value: 5 },
    { label: "Apple Music", value: 2 },
  ];

  const handleCreatePlatlist = async (auxObj: any) => {
    let resp = await playlistService.createPlaylist(
      auxObj.name,
      auxObj.imgUrl,
      auxObj.services
    );
    if (resp.status === 200) {
      handleNewPlaylist();
      setIsAddInfoVisible(false);
    } else {
      console.log(resp.status, resp.data.error);
    }
  };

  const addRender = () => {
    if (playlist.length >= 4) {
      setIsVisible(false);
    }
    let added = { url: "", service: 0 };
    setPlaylist([...playlist, added]);
  };

  const removeRender = (pos: number) => {
    if (playlist.length <= 5) {
      setIsVisible(true);
    }
    let res = playlist.filter((element: any, index: number) => index !== pos);

    setPlaylist(res);
  };

  const infoHandler = (index: number, e: any) => {
    let info = [...playlist];
    info[index][e.target.name] = e.target.value;
    setPlaylist(info);
  };

  const savePlaylist = () => {
    let auxObj = {
      name: value.name,
      imgUrl: value.imgUrl,
      services: playlist,
    };

    if (auxObj.services.length === 0) {
      console.log("You must  have at least one service selected");
    } else {
      handleCreatePlatlist(auxObj);
    }
  };

  useEffect(() => {
    if (playlist.length <= 0) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [playlist.length]);

  return (
    <div className="display-flex flex-column">
      {playlist.map((input: any, index: number) => (
        <div className="m-4" key={index}>
          <InputText
            placeholder="playlist url"
            className="mr-3"
            name="url"
            value={input.url}
            onChange={(e) => infoHandler(index, e)}
          />
          <Dropdown
            options={serviceSelectItems}
            value={input.service}
            name="service"
            placeholder={"select service"}
            onChange={(e) => infoHandler(index, e)}
          />
          <Button
            className="p-button-danger p-button-text p-button-rounded"
            icon="pi pi-times"
            onClick={() => {
              removeRender(index);
            }}
          />
        </div>
      ))}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {isVisible ? (
          <div>
            <Button
              className="p-button-outlined p-button-rounded "
              icon="pi pi-plus"
              style={{ color: "#488ccb" }}
              onClick={() => addRender()}
            />
          </div>
        ) : (
          <div></div>
        )}

        <Button
          label="Save"
          style={{ backgroundColor: "#488ccb" }}
          disabled={isDisabled}
          onClick={() => savePlaylist()}
        />
      </div>
    </div>
  );
};

export default AddInfo;
