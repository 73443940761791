import DashboardCardList from '../../components/dashboardCard/dashboardCardList';
import DashboardTable from '../../components/dashboardTable/dashboardTable';
import { StaisticsCardSkeleton } from '../../components/skeleton/staisticsCardSkeleton';
import { DashboardSkeleton } from '../../components/skeleton/dashboardSkeleton';

import './dashboard.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../features/store';

const DashboardPage = () => {
  const isLoading = useSelector((state: RootState) => state.status.loading);

  return (
    <div className="page dashboard-page">
      {isLoading && (
        <>
          <StaisticsCardSkeleton />
          <DashboardSkeleton />
        </>
      )}

      <span className="" style={{display: isLoading ? 'none' : 'block'}}>
        <span className="w-full" style={{ maxWidth: '1200px' }}>
          <div className="text-600 font-bold text-2xl">Dashboard</div>
        </span>

        <DashboardCardList />
        <DashboardTable />
      </span>
    </div>
  );
};

export default DashboardPage;
