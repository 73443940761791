import React, {useState, useEffect} from 'react'
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from "primereact/api";
import { InputText } from 'primereact/inputtext';

import { Lovedone } from '../../models/lovedone.model'
import LovedonesService from '../../services/lovedones.service';

import { DashboardSkeleton } from '../../components/skeleton/dashboardSkeleton';

import moment from 'moment'

export const LovedonesTable = ({toggleModal, setLovedone}:any) => {
  const [lovedones, setLovedones] = useState<Lovedone[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState<any>(null);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    setGlobalFilterValue("");
  };

  const getLovedones = async() => {
    const response = await LovedonesService.getLovedones();

    if(response.status === 200) {
      const data = response.data.map((lovedone:Lovedone) => {
        return(
          {
            ...lovedone,
            fullName: `${lovedone.firstName} ${lovedone.lastName}`,
            createdAt: moment(lovedone.createdAt).utc().format('MM-DD-YYYY'),
            updatedAt: moment(lovedone.updateAt).utc().format('MM-DD-YYYY')
          }
        )
      })
      setLovedones(data)
    } else {
      console.error(response)
    }

    setIsLoading(false)
  }

  useEffect(()=> {
    getLovedones();
    initFilters();
  },[])

  const onGlobalFilterChange = (e:any) => {
    const value = e.target.value;
    let _filters1 = { ...filters };
    _filters1["global"].value = value;

    setFilters(_filters1);
    setGlobalFilterValue(value);
  };

	const clearFilter = () => {
    initFilters();
  };

  const titleTable = () => {
		return(
			<div className="flex justify-content-between">
				<div>Lovedones</div>
			<div>
				<Button
					icon="pi pi-filter-slash"
					label="Clear"
					className="p-button-text mr-1"
					onClick={clearFilter}
				/>
				<div className="p-input-icon-right">
					<i className="pi pi-search" />
					<InputText
						value={globalFilterValue}
						onChange={onGlobalFilterChange}
						placeholder="Search"
					/>
				</div>
			</div>
		</div>
		)
	}

  const setLovedoneSelected = (row:any) => {
    toggleModal()
    setLovedone(row)
  }

  const actionBodyTemplate = (row:Lovedone) => {
    return (
      <Button
        onClick={() => setLovedoneSelected(row)}
        label="Select"
        style={{ backgroundColor: '#488ccb' }}
      />
    )
  }

  return (
    <div>
      {
        isLoading
        ? <DashboardSkeleton/>
        :
          <Card title={titleTable} className='shadow-2'>
            <DataTable
              dataKey="id"
              className='table'
              value={lovedones}
              responsiveLayout="scroll"
              size="small"
              stripedRows
              paginator
              rows={10}
              rowsPerPageOptions={[10,20,30]}
              selectionMode="single"
              selection
              breakpoint="960px"
              sortField='status'
              sortOrder={1}

              filters={filters}
              filterDisplay={"menu"}
              scrollable
              scrollHeight="600px"
            >
              <Column sortable field="fullName" header="Full Name"></Column>
              <Column sortable field="email" header="Email"></Column>
              <Column sortable field="createdAt" header="Created At"></Column>
              <Column sortable field="updatedAt" header="Updated At"></Column>
              <Column body={actionBodyTemplate} header="Options"></Column>
            </DataTable>
          </Card>
      }
    </div>
  )
}
