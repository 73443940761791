import axios from 'axios';
import { setupInterceptors } from './api.interceptors';

const API_URL = process.env.REACT_APP_PUBLIC_ENDPOINT_AUTH

const api = setupInterceptors(  
  axios.create({
    baseURL: API_URL,
    headers: {
      'Content-type': 'application/json',
    },
    withCredentials: true,
  })
);

export default api;
