import { useRef, useState } from "react";
import { Autocomplete } from "@react-google-maps/api";

import { InputText } from "primereact/inputtext";

import { useDispatch, useSelector } from "react-redux";

import {
  setLocationLatitude,
  setLocationLongitude,
} from "../../features/location";

export const AutoComplete = ({ setMarkerCoords, setCenterCoords }: any) => {
  const destinationRef = useRef<any>();
  const [searchResult, setSearchResult] = useState<any>();

  const { lat, lng } = useSelector((state: any) => state.location);
  const dispatch = useDispatch();

  // onLoad to get autocomplete
  const onLoad = (autocomplete: any) => {
    setSearchResult(autocomplete);
  };

  const onPlaceChanged = () => {
    if (searchResult != null) {
      setMarkerCoords({
        lat: searchResult.getPlace().geometry.location.lat(),
        lng: searchResult.getPlace().geometry.location.lng(),
      });
      
      setCenterCoords({
        lat: searchResult.getPlace().geometry.location.lat(),
        lng: searchResult.getPlace().geometry.location.lng(),
      });
    } else {
      alert("Please enter text");
    }

    destinationRef.current.value = "";
  };

  // const dispatchLocation = () => {
  //   dispatch(setLocationLatitude(11111));
  //   dispatch(setLocationLongitude(-222222));
  // };

  return (
    <>
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        <InputText
          style={{ width: "280px" }}
          id="username"
          placeholder="Search Location"
          ref={destinationRef}
        />
      </Autocomplete>
    </>
  );
};
