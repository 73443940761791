import api from "../utils/api";

class PlaylistService {
  async getAllPlaylists() {
    return await api.get("/playlist/all/");
  }

  async createPlaylist(name: any, imgUrl: any, services: any) {
    let payload = {
      name: name,
      imgUrl: imgUrl,
      services: services,
    };
    return await api.post("/playlist/create/", payload);
  }

  async updatePlaylist(
    name: any,
    imgUrl: any,
    services: any,
    id: number,
    serviceId: any
  ) {
    console.log('Updatename id : ', id, name);


    let payload = {
      name: name,
      imgUrl: imgUrl,
      services: services,
      id: id,
      serviceId: serviceId,
    };
    return await api.patch("/playlist/update/", payload);
  }

  async removePlaylist(id: any) {
    return await api.delete(`/playlist/delete/${id}`);
  }
}

export default new PlaylistService();
