import { SupportTicket } from '../models/supportTicket.model';
import api from "../utils/api";

class SupportService {

    async create_support_ticket(ticket: SupportTicket) {
        return await api.post("/help/support_ticket/", ticket);
    }

}

export default new SupportService();