import { useState, useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';
import { fbDb } from '../firebase';
import { DashboardStatistics } from '../../models/dashboardStatistics.model';

const useDashboardStatistics = (): DashboardStatistics => {
  const [statistics, setStatistics] = useState<DashboardStatistics>({
    feelingGood: 0,
    feelingBad: 0,
    totalLovedones: 0,
    activeProviders: 0,
    totalProviders: 0,
  });

  useEffect(() => {
    if (!fbDb) return;
    const unsub = onSnapshot(doc(fbDb, 'dashboard', 'totals'), (doc) => {
      setStatistics(doc.data() as DashboardStatistics);
    });
    return () => unsub();
  }, [fbDb]);

  return statistics;
};

export default useDashboardStatistics;
