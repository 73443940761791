import { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";

import { CONSTANTS } from "../../utils/constants";
import { Button } from "primereact/button";
import { Timeline } from "primereact/timeline";
import requestService from "../../services/request.service";

import UserInformationDialog from "./userInformationDialog";

import moment from 'moment'
import { Loader } from '../loader/loader';
import { EventType } from '../../models/enums';

const TimeLineDialog = ({ rowSelected, visible, toggleModal }: any) => {
  const [feedback, setFeedback] = useState<string>("");
  const [isUserInformationVisible, setIsUserInformationVisible] =
    useState<boolean>(false);
  const [timeLine, setTimeLine] = useState([]);

  const getFeedback = async () => {
    const response = await requestService.getFeedback(rowSelected?.id);

    if (response?.status === 200) {
      setFeedback(response.data?.description || "");
      setTimeLine(response?.data?.timeline);
    } else {
      console.log("error getting feedback");
    }
  };

  useEffect(() => {
    getFeedback();
  }, []);

  const color = (item:any) => {
    const type = item?.type ? +item?.type : '';
    switch(type) {
      case EventType.REQUEST_CREATED:
        return '#F58337'

      case EventType.REQUEST_COMPLETED:
        return '#0ead69';

      default:
        return 'rgb(79,195,218)'
    }
  };

  const customizedMarker = (item: any) => {
    return (
      <span
        className="flex w-1rem h-1rem align-items-center justify-content-center text-white border-circle z-1 shadow-1"
        style={{ backgroundColor: `${color(item)}` }}
      ></span>
    );
  };

  return (
    <Dialog
      visible={visible}
      style={{ minWidth: "800px", maxWidth: "800px" }}
      header={"Wellness Check Info"}
      draggable={false}
      onHide={() => toggleModal()}
      headerStyle={{
        backgroundColor: "#488ccb",
        textAlign: "center",
        color: "#ffffff",
      }}
    >
      {isUserInformationVisible && (
        <UserInformationDialog
          lovedOneId={rowSelected?.lovedone?.id}
          isUserInformationVisible={isUserInformationVisible}
          setIsUserInformationVisible={setIsUserInformationVisible}
        />
      )}

      <div className="mb-4">
        <div className="mb-2 pb-2 text-center">
          <div className="text-700 font-bold text-lg">Request Feedback</div>
          <span className="">{feedback || <i>No info</i>}</span>
        </div>

        <div className=" pt-2 w-12 text-center flex flex-row justify-content-evenly align-items-center">
          {/* <div className="ml-5 absolute z-1 bg-primary"> */}
          <div className="">
            <img
              src={`${rowSelected?.lovedone?.pictureUrl || CONSTANTS.NO_IMAGE}`}
              alt={`${rowSelected?.lovedone?.firstName}`}
              className="border-circle"
              width="150px"
              height="150px"
            />

            <div className="text-700 font-bold text-2xl flex flex-column align-items-center">
              <a
                className="cursor-pointer underline mb-2"
                onClick={() => setIsUserInformationVisible(true)}
              >
                {`${rowSelected?.lovedone?.firstName} ${rowSelected?.lovedone?.lastName}`}
              </a>

              <a
                target="_blank"
                href={`https://www.google.com/maps/search/?api=1&query=${rowSelected.location}`}
                style={{
                  pointerEvents: !rowSelected.location ? "none" : "auto",
                }}
              >
                <Button
                  label={
                    rowSelected.location ? "Location" : "Location not available"
                  }
                  className="p-button-sm p-button-rounded p-button-outlined"
                  icon="pi pi-map-marker"
                  disabled={!rowSelected.location}
                />
              </a>
            </div>
          </div>

          <div>
            {timeLine.length ? (
              <Timeline
                className="fadein animation-duration-1000"
                value={timeLine}
                marker={customizedMarker}
                opposite={(item) => (
                  <small className="text-color-secondar">
                    {moment(item.date).format("HH:mm")}
                  </small>
                )}
                content={(item) => (
                  <div className="">
                    <span
                      className="inline-block text-right"
                      style={{ color: "rgb(168 168 168)", width: "85px" }}
                    >
                      {item.creator}
                    </span>{" "}
                    -<span className=""> {item.description}</span>
                  </div>
                )}
              />
            ) : (
              <div>
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default TimeLineDialog;
