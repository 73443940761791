import api from "../utils/api";

class RequestService {
  async getRequest() {
    return await api.get('/request/')
  }

  async getFeedback(requestId: number | string) {
    if (!requestId) return null;
    
    return await api.post('/feedback/by_request/', { requestId })
  }
}

export default new RequestService()