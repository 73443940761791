import { useSelector } from "react-redux";
import type { RootState } from "../../features/store";
import { Link } from "react-router-dom";
import AuthService from "../../services/auth.service";
import "./navBar.scss";
import logoSm from "../../assets/images/logo-blue.png";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  const user = useSelector((state: RootState) => state.auth.user);
  const navigate = useNavigate();

  const logout = () => {
    AuthService.logout();
    navigate("/public/login");
  };

  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex">
          <div className="navbar-brand-box">
            <a href="index.html" className="logo logo-light">
              <span className="logo-sm">
                <img src={logoSm} alt="" height="22" />
              </span>
              <span className="logo-lg">
                <img src={logoSm} alt="" height="45" />
              </span>
            </a>
          </div>

          <button
            type="button"
            className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
            data-bs-toggle="collapse"
            data-bs-target="#topnav-menu-content"
          >
            <i className="fa fa-fw fa-bars"></i>
          </button>

          {/* <div className="dropdown dropdown-mega d-none d-lg-block ms-2">
            <button
              type="button"
              className="btn header-item waves-effect"
              data-bs-toggle="dropdown"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <span key="t-megamenu">Users</span>
              <i className="mdi mdi-chevron-down"></i>
            </button>
            <div className="dropdown-menu dropdown-megamenu">
              <div className="row">
                <div className="col-sm-8">
                  <div className="row">
                    <div className="col-md-4">
                      <h5 className="font-size-14" key="t-ui-components">
                        UI Components
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="d-flex flex-wrap gap-2">
          <Link to="/dashboard911">
              <button
                type="button"
                className="btn waves-effect waves-light header-item"
              >
                Dispatch 911
              </button>
            </Link>

            <Link to="/dashboard">
              <button
                type="button"
                className="btn waves-effect waves-light header-item"
              >
                Dashboard
              </button>
            </Link>            

            <Link to="/playlist">
              <button
                type="button"
                className="btn waves-effect waves-light header-item"
              >
                Playlists
              </button>
            </Link>

            <Link to="/administrators">
              <button
                type="button"
                className="btn waves-effect waves-light header-item"
              >
                Administrators
              </button>
            </Link>

            <Link to="/reports">
              <button
                type="button"
                className="btn waves-effect waves-light header-item"
              >
                Reports
              </button>
            </Link>            
          </div>
        </div>

        <div className="d-flex">
          {/* <div className="dropdown d-inline-block">
            <button
              type="button"
              className="btn header-item noti-icon waves-effect"
              id="page-header-notifications-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="bx bx-bell bx-tada"></i>
              <span className="badge bg-danger rounded-pill">3</span>
            </button>
            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown">
              <div className="p-3">
                <div className="row align-items-center">
                  <div className="col">
                    <h6 className="m-0" key="t-notifications">
                      Notifications
                    </h6>
                  </div>
                  <div className="col-auto">
                    <a href="#!" className="small" key="t-view-all">
                      View All
                    </a>
                  </div>
                </div>
              </div>

              <div data-simplebar style={{ maxHeight: '230px' }}>
                <a href="#" className="text-reset notification-item">
                  <div className="d-flex">
                    <div className="avatar-xs me-3">
                      <span className="avatar-title bg-primary rounded-circle font-size-16">
                        <i className="bx bx-bell"></i>
                      </span>
                    </div>
                    <div className="flex-grow-1">
                      <h6 className="mb-1" key="t-your-order">
                        Help Requested
                      </h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1" key="t-grammer">
                          User Name First name pressed MERA button
                        </p>
                        <p className="mb-0">
                          <i className="mdi mdi-clock-outline"></i> <span key="t-min-ago">3 min ago</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div> */}

          <div className="dropdown d-inline-block">
            <button
              type="button"
              className="btn header-item waves-effect"
              id="page-header-user-dropdown"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="d-none d-xl-inline-block ms-1" key="t-henry">
                Hi {user?.firstName}
              </span>
              <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </button>
            <div className="dropdown-menu dropdown-menu-end">
              {/* <a className="dropdown-item" href="#">
                <i className="bx bx-user font-size-16 align-middle me-1"></i> <span key="t-profile">Profile</span>
              </a>
              <a className="dropdown-item" href="#">
                <i className="bx bx-wallet font-size-16 align-middle me-1"></i> <span key="t-my-wallet">My Wallet</span>
              </a>
              <a className="dropdown-item d-block" href="#">
                <span className="badge bg-success float-end">11</span>
                <i className="bx bx-wrench font-size-16 align-middle me-1"></i> <span key="t-settings">Settings</span>
              </a>
              <a className="dropdown-item" href="#">
                <i className="bx bx-lock-open font-size-16 align-middle me-1"></i> <span key="t-lock-screen">Lock screen</span>
              </a>
              <div className="dropdown-divider"></div> */}
              <a className="dropdown-item text-danger" href="#">
                <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
                <span key="t-logout" onClick={() => logout()}>
                  Logout
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default NavBar;
