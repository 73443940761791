import { Toast } from 'primereact/toast';
import { Subject } from 'rxjs';

interface ToastOptions {
  severity: ToastType;
  summary: string;
  detail: string;
}

interface IUseToastProps {
  toastRef: React.RefObject<Toast>;
}

export enum ToastType {
    SUCCESS = 'success',
    INFO = 'info',
    WARN = 'warn',
    ERROR = 'error',
}

//HOOK
const useToast = ({ toastRef }: IUseToastProps) => {
  const showToast = ({ severity, summary, detail }: ToastOptions) => {
    if (toastRef.current) {
      toastRef.current.show({ severity, summary, detail });
    }
  };

  return { toastRef, showToast };
};

export default useToast;


// SUBJECT
const toastSubject = new Subject<any>();

export const showNextToast = (severity: ToastType, summary: string, detail: string) => {
  toastSubject.next({ severity, summary, detail });
};

export const toastObservable = toastSubject.asObservable();
