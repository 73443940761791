import React, { useEffect, useState } from "react";
import { AddPolygonDialog } from "../dialogs/addPolygonDialog";
import { DrawingManager } from "@react-google-maps/api";
import { Polygon } from '../../models/polygon';

const DeafultOptions: google.maps.drawing.DrawingManagerOptions = {
  drawingControl: true,
  polygonOptions: {
    fillColor: "#2196F3",
    strokeColor: "#2196F3",
    fillOpacity: 0.5,
    strokeWeight: 2,
    clickable: true,
    editable: true,
    draggable: true
  },
};


interface DrawerProps {
  polygon: Polygon,
  setPolygon: (polygon:Polygon) => void;
}

export const Drawer = ({polygon, setPolygon}:DrawerProps) => {
  const [isVisibleDialog, setIsVisibleDialog] = useState<boolean>(false);
  const [drawnPolygon, setDrawnPolygon] = useState<any>();
  const [options, setOptions] = useState<google.maps.drawing.DrawingManagerOptions>(DeafultOptions);

  useEffect(() => {
    // set polygon only
    setOptions({...options, drawingControlOptions: { drawingModes: [ google.maps.drawing.OverlayType.POLYGON ] } })      
  }, []);

  const onPolygonComplete = React.useCallback(function onPolygonComplete( poly: any ) {
    // Referece drawn polygon
    setDrawnPolygon(poly)
    
    const polyArray = poly.getPath().getArray();    
    let auxArray: any = [];
    polyArray.forEach(function (path: any) {
      auxArray.push({ lat: path.lat(), lng: path.lng() });
      console.log({ lat: path.lat(), lng: path.lng() });
    });
    
    setIsVisibleDialog(true);
    // setPaths(auxArray);
    setPolygon({...polygon, path: auxArray})
  },[]);

  const handleCloseDialog = () => {
    setIsVisibleDialog(false);
    // Remove drawn polygon 
    drawnPolygon.setMap(null);
    setPolygon({name:'', path: []});
  }

  return (
    <>
      <DrawingManager        
        drawingMode={google.maps.drawing.OverlayType.POLYGON}
        options={options}
        onPolygonComplete={onPolygonComplete}
      />
         
      <AddPolygonDialog 
        isVisibleDialog = { isVisibleDialog }
        setIsVisibleDialog = { setIsVisibleDialog }
        handleCloseDialog = { handleCloseDialog }
        polygon = { polygon }
        setPolygon = { setPolygon }
      />
      
    </>
  );
};
