import React, { useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import AuthService from "../../../services/auth.service";
import { Link, useNavigate } from "react-router-dom";
import { Message } from "primereact/message";

const RegisterPage = () => {
  const [userEmail, setUserEmail] = useState<string>("");
  const [userPassword, setUserPassword] = useState<string>("");
  const [userFirst, setUserFirst] = useState<string>("");
  const [userLast, setUserLast] = useState<string>("");
  const [userConfirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const navigate = useNavigate();

  const handleRegister = async () => {

    if (userPassword !== userConfirmPassword) {
      setError("Passwords do not match");
      return;
    }

    let resp = await AuthService.register(
      userFirst,
      userLast,
      userEmail,
      userPassword,
      userConfirmPassword
    );
    if (resp.status === 201) {
      setIsAlert(true);
      setTimeout(() => navigate("/dashboard"), 5000);
    } else {
      setError(resp.data?.error || resp.data);
    }
  };

  useEffect(() => {}, [error]);

  return (
    <div className="account-pages my-2 pt-sm-0">
      {isAlert && (
        <div className="alert alert-success " role="alert">
          <h2 className="alert-heading">Well done!</h2>
          <p>Aww yeah, you successfully created your meta account.</p>
          <hr />
          <p className="mb-0">You will be redirected to the login page now.</p>
        </div>
      )}
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="card overflow-hidden">
              <div className="bg-primary bg-soft">
                <div className="row">
                  <div className="col-7">
                    <div className="text-primary p-4">
                      <h5 className="text-primary">Free Register</h5>
                      <p>Get your free MERA account now.</p>
                    </div>
                  </div>
                  <div className="col-5 align-self-end"></div>
                </div>
              </div>
              <div className="card-body pt-0">
                {/* <div>
                  <a href="index.html">
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img
                          src="assets/images/logo.svg"
                          alt=""
                          className="rounded-circle"
                          height="34"
                        />
                      </span>
                    </div>
                  </a>
                </div> */}
                <div className="p-2">
                  {error && (
                    <Message
                      severity="error"
                      text={error}
                      className="mb-3 col-12"
                    />
                  )}
                  <div className="needs-validation">
                    <div className="mb-3">
                      <label htmlFor="firstName" className="form-label">
                        First Name
                      </label>
                      <InputText
                        onBlur={(e) => setUserFirst(e.target.value)}
                        type="text"
                        className="w-full mb-3"
                      />
                      <div className="invalid-feedback">Please First Name</div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="useremail" className="form-label">
                        Last Name
                      </label>
                      <InputText
                        onBlur={(e) => setUserLast(e.target.value)}
                        type="text"
                        className="w-full mb-3"
                      />
                      <div className="invalid-feedback">
                        Please Enter Last Name
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="useremail" className="form-label">
                        Email
                      </label>
                      <InputText
                        onBlur={(e) => setUserEmail(e.target.value)}
                        type="text"
                        className="w-full mb-3"
                      />
                      <div className="invalid-feedback">Please Enter Email</div>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="userpassword" className="form-label">
                        Password
                      </label>
                      <InputText
                        onBlur={(e) => setUserPassword(e.target.value)}
                        type="password"
                        className="w-full mb-3"
                      />
                      <div className="invalid-feedback">
                        Please Enter Password
                      </div>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="userpassword" className="form-label">
                        Confirm Password
                      </label>
                      <InputText
                        onBlur={(e) => setConfirmPassword(e.target.value)}
                        type="password"
                        className="w-full mb-3"
                      />
                      <div className="invalid-feedback">
                        Please Re-type Password
                      </div>
                    </div>

                    <div className="mt-4 d-grid">
                      <button
                        className="btn btn-primary waves-effect waves-light"
                        onClick={handleRegister}
                      >
                        Register
                      </button>
                    </div>

                    {/* <ul className="list-inline">
                        <li className="list-inline-item">
                          <a
                            href="javascript::void()"
                            className="social-list-item bg-primary text-white border-primary"
                          >
                            <i className="mdi mdi-facebook"></i>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            href="javascript::void()"
                            className="social-list-item bg-info text-white border-info"
                          >
                            <i className="mdi mdi-twitter"></i>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            href="javascript::void()"
                            className="social-list-item bg-danger text-white border-danger"
                          >
                            <i className="mdi mdi-google"></i>
                          </a>
                        </li>
                      </ul> */}

                    <div className="mt-4 text-center">
                      <p className="mb-0">
                        By registering you agree to the MERA{" "}
                        <a href="#" className="text-primary">
                          Terms of Use
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 text-center">
              <div>
                <p>
                  Already have an account ?{" "}
                  <Link to={"/public/login"} className="fw-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
