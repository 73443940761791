import React from 'react'
import { Skeleton } from 'primereact/skeleton';

export const StaisticsCardSkeleton = () => {
  return (
    <>
      {/* <Skeleton width="12rem" height='2rem' className="mb-3 align-self-start"></Skeleton> */}
      <span className='flex flex-row justify-content-between mt-4' style={{width: '1200px'}}>

        {[1,2,3].map((card, index) => (
          <div key={index} className='card shadow-1' style={{width: '30%'}}>
            <div className="custom-skeleton p-4">
              <div className="flex flex-row mb-3 justify-content-between">
                <div>
                  <Skeleton width="7rem" className="mb-2"></Skeleton>
                </div>
                <Skeleton size="3rem" className="mr-2"></Skeleton>
              </div>
              <div className="flex mt-3">
                <Skeleton className='mr-3' width="1rem" ></Skeleton>
                <Skeleton className='w-10' ></Skeleton>
              </div>
            </div>
        </div>
        ))}
      </span>
    </>
  )
}
