import React,{useState,useEffect} from 'react'
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from "primereact/api";
import { InputText } from 'primereact/inputtext';

import { Administrator } from '../../models/administrators.model';

import './administratorsTable.scss'

export interface AdministratorTableProps {
	administrators: Administrator[]
}

export const AdministratorsTable = ({administrators}:AdministratorTableProps) => {
	const [globalFilterValue, setGlobalFilterValue] = useState("");
	const [filters, setFilters] = useState<any>(null);

	const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    setGlobalFilterValue("");
  };

	useEffect(() => {
		initFilters()
	},[])

	const onGlobalFilterChange = (e:any) => {
    const value = e.target.value;
    let _filters1 = { ...filters };
    _filters1["global"].value = value;

    setFilters(_filters1);
    setGlobalFilterValue(value);
  };

	const clearFilter = () => {
    initFilters();
  };

	const titleTable = () => {
		return(
			<div className="flex justify-content-between">
				<div>Administrators</div>
			<div>
				<Button
					// type="button"
					icon="pi pi-filter-slash"
					label="Clear"
					className="p-button-text mr-1"
					onClick={clearFilter}
				/>
				<div className="p-input-icon-right">
					<i className="pi pi-search" />
					<InputText
						value={globalFilterValue}
						onChange={onGlobalFilterChange}
						placeholder="Search"
					/>
				</div>
			</div>
		</div>
		)
	}

	return (
    <div className='table-container flex-column flex justify-content-center align-items-center'>

		<Card title={titleTable} className='shadow-2'>
			<DataTable
				dataKey="id"
				className='table'
				value={administrators}
				responsiveLayout="scroll"
				size="small"
				stripedRows
				paginator
				rows={10}
				rowsPerPageOptions={[10,20,30]}
				selectionMode="single"
				selection
				breakpoint="960px"
				sortField='status'
				sortOrder={1}

				filters={filters}
				filterDisplay={"menu"}
				scrollable
				scrollHeight="600px"
			>
				<Column sortable field="fullName" header="Full Name"></Column>
				<Column sortable field="email" header="Email"></Column>
				<Column sortable field="lastLogin" header="Last Login"></Column>
				{/* <Column sortable field="role" header="Role"></Column> */}
				<Column sortable field="createdAt" header="Created At"></Column>
				<Column sortable field="updatedAt" header="Updated At"></Column>
			</DataTable>
		</Card>
    </div>
	)
}
