
class LocalStorageService {
    static get(key: string) {
        return JSON.parse(localStorage.getItem(key) || 'null');
    }
    
    static set(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }
    
    static remove(key: string) {
        localStorage.removeItem(key);
    }

    static setToken(token: string) {
        this.set('token', token);
    }

    static getToken() {
        return this.get('token');
    }

    static removeToken() {
        this.remove('token');
    }

}

export default LocalStorageService;