import { createSlice } from '@reduxjs/toolkit';
import { Subject } from 'rxjs';
import { RequestStatus } from '../../models/enums';
import { ActiveRequestsType, ChangedRequestEvent } from './types';

const initialState: ActiveRequestsType = {
  requests: [],
  notificationSubject: new Subject<ChangedRequestEvent>()
};

const activeRequestsSlice = createSlice({
  name: 'activeRequests',
  initialState,
  reducers: {
    addBatchActiveRequests(state, action) {
        state.requests = action.payload;
    },
    addActiveRequest(state, action) {
        if(!state.requests.find((r) => r.id === action.payload.id)) {
            state.requests.push(action.payload);
            state.notificationSubject.next({ type: 'added', id: action.payload.id });
        }
        // else {
        //     this.updateActiveRequest(state, action);
        // }
    },
    updateActiveRequest(state, action) {
        state.requests = state.requests.map((r) => {
            if (r.id === action.payload.id) {
                return action.payload;
            }
            return r;
        });
        state.notificationSubject.next({ type: 'modified', id: action.payload.id });
    },
    markRequestAsCompleted(state, action) {
        state.requests = state.requests.map((r) => {
            if (r.id === action.payload.id) {
                return { ...r, status: RequestStatus.COMPLETED };
            }
            return r;
        });
        state.notificationSubject.next({ type: 'completed', id: action.payload.id });
    },
    removeActiveRequest(state, action) {
      state.requests = state.requests.filter((request) => request !== action.payload);
      state.notificationSubject.next({ type: 'removed', id: action.payload.id });
    },
  },
});

export const { addBatchActiveRequests, addActiveRequest, removeActiveRequest, updateActiveRequest, markRequestAsCompleted } = activeRequestsSlice.actions;
export default activeRequestsSlice.reducer;
