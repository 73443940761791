import { useEffect, useState } from 'react';
import { Polygon, Marker } from '@react-google-maps/api';
import greenIcon from '../../assets/images/greenIcon.png';
import redMark from '../../assets/images/redMark.png';
import geofenceService from '../../services/geofence.service';
import { Polygon as PolygonModel } from '../../models/polygon';

const options = {
  drawingControl: true,
  polygonOptions: {
    fillColor: '#2196F3',
    strokeColor: '#2196F3',
    fillOpacity: 0.4,
    strokeWeight: 1,
    clickable: true,
    editable: false,
    draggable: false,
    zindex: 1,
  },
};

export const Polygons = ({ markerCoords, polygon }: any) => {
  const [polygonsArray, setPolygosArray] = useState<PolygonModel[]>([]);

  useEffect(() => {
    loadPolygons();
  }, []);

  useEffect(() => {
    setPolygosArray([...polygonsArray, { ...polygon }]);
  }, [polygon?.name]);

  useEffect(() => {
    checkMarkerIsInPolygon();
  }, [markerCoords]);

  const loadPolygons = async () => {
    const resp = await geofenceService.getActiveGeofences();
    if (resp.status === 200) {
      setPolygosArray([...resp.data.map((x: any) => x.polygon)]);
    }
  };

  const checkMarkerIsInPolygon = () => {
    if (markerCoords) {
      var marker = new google.maps.LatLng(markerCoords.lat, markerCoords.lng);
      var locatedMarker = redMark;

      for (let i = 0; i < polygonsArray.length; i++) {
        const geoFence = new google.maps.Polygon({
          paths: polygonsArray[i].path,
        });

        if (google.maps.geometry.poly.containsLocation(marker, geoFence)) {
          return (locatedMarker = greenIcon);
        }
      }
      return locatedMarker;
    }
  };


  return (
    <>
      {polygonsArray.map((polygon: any) => (
        <Polygon key={polygon.name} path={polygon?.path} options={options.polygonOptions} />
      ))}

      {/* Search Location Marker */}
      {markerCoords && <Marker position={markerCoords} animation={google.maps.Animation.DROP} icon={checkMarkerIsInPolygon()} />}
    </>
  );
};
