import React, { useState, useEffect } from 'react'
import { DashboardSkeleton } from '../../components/skeleton/dashboardSkeleton';
import { AdministratorsTable } from '../../components/administatorsTable/administratorsTable';
import administratorsService from '../../services/administrators.service';
import { Administrator } from '../../models/administrators.model';
import moment from 'moment'
import './administrators.scss'

const Administators = () => {
  const [ administrators, setAdministrators ] = useState<Administrator[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(true)

  const getAdministrators = async() => {
    const response = await administratorsService.getAdministrators()

    if(response.status === 200) {
      const data = response.data.map((data:Administrator) => {
        return(
          {
            ...data,
            lastLogin: `${moment(data.lastLogin).isValid() ? moment(data.lastLogin).format('MM-DD-YYYY') : ''}`,
            createdAt: moment(data.createdAt).utc().format('MM-DD-YYYY'),
            updatedAt: moment(data.updatedAt).utc().format('MM-DD-YYYY'),
            fullName: `${data.firstName} ${data.lastName}`
          }
        )
      })
      setAdministrators(data)
    }else {
      console.error(response)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    getAdministrators()
  },[])

  return (
    <div className='page dashboard-page h-full flex flex-column align-items-center'>

      {
        isLoading
        ? <DashboardSkeleton/>
        : <AdministratorsTable administrators={administrators}/>
      }

    </div>
  )
}

export default Administators;
