import React, { useState } from "react";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { Sidebar } from "primereact/sidebar";

export interface SidebarI {
  isSidebarVisible: boolean;
  setIsSidebarVisible: (e: boolean) => void;
}

const typeOfCall = [
  { name: "Type 1", code: "1" },
  { name: "Type 2", code: "2" },
  { name: "Type 3", code: "3" },
  { name: "Type 4", code: "4" },
  { name: "Type 5", code: "5" },
];

export const SidebarRequestForm = ({
  isSidebarVisible,
  setIsSidebarVisible,
}: SidebarI) => {
  const [value, setValue] = useState<any>();
  const [typeofCall, setTypOfCall] = useState<any>();

  return (
    <div className="card flex justify-content-center">
      <Sidebar
        style={{ width: "400px" }}
        // className="p-sidebar-sm"
        visible={isSidebarVisible}
        position="right"
        onHide={() => setIsSidebarVisible(false)}
      >
        <h2 className="border-bottom-1 surface-border pb-2 mb-4">
          Create New Request
        </h2>{" "}
        <div className="field">
          <label htmlFor="firstname1">Location</label>
          <InputText
            id="firstname1"
            type="text"
            className="p-2 text-color  focus:border-primary w-full"
          />
        </div>
        <div className="field">
          <label htmlFor="typeOfCall">Type of call</label>
          <Dropdown
            value={typeofCall}
            onChange={(e) => setTypOfCall(e.value)}
            options={typeOfCall}
            optionLabel="name"
            placeholder="Select a type of call"
            className="w-full "
          />
        </div>
        <div className="field">
          <label htmlFor="firstName">First Name</label>
          <InputText
            id="firstName"
            type="text"
            className="p-2 text-color  focus:border-primary w-full"
          />
        </div>
        <div className="field">
          <label htmlFor="lastname1">Last Name</label>
          <InputText
            id="lastname1"
            type="text"
            className="p-2 text-color  focus:border-primary w-full"
          />
        </div>
        <div className="field">
          <label htmlFor="identifyUser">How to identify the user?</label>
          <InputTextarea id="identifyUser" className="w-full" />
        </div>
        <div className="field">
          <label htmlFor="description">Description of the situation</label>
          <InputTextarea
            id="description"
            className="w-full"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <div className="field">
          <label htmlFor="description">Responder: </label>
          <div className="flex">
            <div className="field-radiobutton">
              <RadioButton inputId="ingredient1" name="pizza" value="Cheese" />
              <label htmlFor="ingredient1" className="ml-2">
                Auto Assignment
              </label>
            </div>
            <div className="field-radiobutton ml-4">
              <RadioButton
                inputId="ingredient2"
                name="pizza"
                value="Mushroom"
              />
              <label htmlFor="ingredient2" className="ml-2">
                Manual Assignment
              </label>
            </div>
          </div>
        </div>
        <div className="w-full mt-4 mb-2 text-right">
          <Button
            className="p-button-outlined p-button-secondary mr-3"
            label="Cancel"
            onClick={() => setIsSidebarVisible(false)}
          />
          <Button label="Submit" onClick={() => setIsSidebarVisible(true)} />
        </div>
      </Sidebar>
    </div>
  );
};
