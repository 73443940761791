import "./privacyPolicy.scss";
import PrivacyPolicyContent from './privacyPolicyContent';


const PrivacyPolicy = () => {

    return (
        <div className="page page__public privacy-policy">
            <h1 className="text-900 text-center font-medium">Privacy Policy</h1>
            <div className="text-center">
                <p className='mb-1'>You need any help? contact us at <a href="mailto: admin@housecallwi.com">admin@housecallwi.com</a></p>
                <p>Want to delete yoru account? fill <a href="/support/delete_account">this form</a></p>
            </div>
            <PrivacyPolicyContent />
        </div>
    )

};

export default PrivacyPolicy;