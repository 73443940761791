import React from "react";
// import UserTable from "../../components/userTable/userTable";

const HomePage = () => {
  return (
    <div>
      {/* <UserTable></UserTable> */}
    </div>
  );
};

export default HomePage;
