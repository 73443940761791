import { GeoFence } from '../models/polygon';
import api from '../utils/api';

class GeofenceService {
  async getGeofences() {
    return await api.get('/dispatch/geofence/');
  }

  async getActiveGeofences() {
    return await api.get('/dispatch/geofence/active/');
  }

  async saveGeofence(geofence: GeoFence) {
    return await api.post('/dispatch/geofence/', geofence);
  }
}

export default new GeofenceService();
