import CardDashboard from "./dashboardCard";
import "./dashboardCardList.scss";

import useDashboardStatistics from '../../utils/hooks/useDashoardStatistics';

const DashboardCarList = () => {

  const { activeProviders, feelingGood, feelingBad, totalLovedones, totalProviders } = useDashboardStatistics();

    return (
      <>
        <div className="dashboard-card-list  mt-2">
          <CardDashboard
            icon="pi pi-thumbs-up"
            title="Feeling Good"
            value={`${feelingGood}`}
            totalValue={`${totalLovedones}`}
            color="#0ead69"
            footer={
              <>
                <span className="text-green-500 font-medium">24 new </span>
                <span className="text-500">since last visit</span>
              </>
            }
          />

          <CardDashboard
            icon="pi pi-thumbs-down"
            title="Feeling Down"
            value={`${feelingBad}`}
            totalValue={`${totalLovedones}`}
            color="#F58337"
            footer={
              <>
                <span className="text-green-500 font-medium">7 </span>
                <span className="text-500">listening to AA</span>
              </>
            }
          />

          {/* <CardDashboard
            icon="pi pi-whatsapp"
            title="Care takers"
            value="10"
            color="bg-cyan-700"
            footer={
              <>
                <span className="text-500">active and ready</span>
              </>
            }
          /> */}

          <CardDashboard
            icon="pi pi-phone"
            title="Active Providers"
            value={`${activeProviders}`}
            totalValue={`${totalProviders}`}
            color="#488ccb"
            footer={
              <>
                <span className="text-500">active and ready</span>
              </>
            }
          />
        </div>
      </>
    );
}

export default DashboardCarList;