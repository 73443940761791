import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, useLocation } from 'react-router-dom';
import { LoggedInStatus } from '../../features/auth/types';
import { RootState } from '../../features/store';

export interface IPrivateRouteProps {
  component: any;
}

const PrivateRoute: FunctionComponent<IPrivateRouteProps> = (props) => {
  const loginStatus = useSelector((state: RootState) => state.auth.loginStatus);
  let location = useLocation();

  // TODO: return a waiting page
  if (loginStatus === LoggedInStatus.NOT_VERIFIED) return null;
  else if (loginStatus === LoggedInStatus.NOT_LOGGED_IN) {
    return <Navigate to="/public/login" state={{ from: location }} replace />;
  }

  return props.component;
};

export default PrivateRoute;
