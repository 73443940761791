import React, { useState, useRef } from 'react'
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { Messages } from 'primereact/messages';
import { RadioButton } from 'primereact/radiobutton';

import { LovedonesTable } from '../../components/LovedonesTable/lovedonesTable'

import { Report } from '../../models/report.model'
import { Lovedone } from '../../models/lovedone.model'

import ReportService from '../../services/report.service';

import moment from 'moment'

import './reports.scss'

export const lovedOneInitalState = {
  id: 0,
  firstName: '',
  lastName: '',
  email: '',
  role: '',
  isActive: false,
  isAdmin: false,
  isStaff: false,
  createdAt: new Date(),
  updateAt: new Date(),
  lastLogin: new Date()
}

const dataRequestInitialState = {
  startDate: '',
  endDate: '',
  all: true,
  lovedone: 0
}

const Reports = () => {
  const [dataRequest, setDataRequest] = useState<any>(dataRequestInitialState)
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [reportCategory, setReportCategory] = useState("All lovedones")
  const [lovedoneSelected, setLovedoneSelected] = useState<Lovedone>()
  const [visible, setVisible] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isHiddenResult, setIsHiddenResult] = useState<boolean>(true)
  const msgs = useRef<any>(null);
  const [report, setReport] = useState<Report>()

  const dialog = () => {
    return (
      <Dialog
        visible={visible}
        header={"LovedOnes table"}
        draggable={false}
        onHide={() =>  setVisible(false)}
        headerStyle={{backgroundColor: '#488ccb', textAlign: 'center', color: '#ffffff'}}
    >
      <LovedonesTable
        toggleModal={toggleModal}
        setLovedone = {setLovedone}
      />
    </Dialog>
    )
  }

  const toggleModal = () => {
    setVisible(!visible);
  };

  const onSpecificLovedoneOptionSelected = (e:any) => {
    setVisible(true)
  }

  const onAllLovedonesOptionSelected = (e:any) => {
    setIsHiddenResult(true)
    setReportCategory(e.value)
    setDataRequest({...dataRequest, all: true, lovedone: 0})
  }

  const setLovedone = (lovedone:any) => {
    setIsHiddenResult(true)
    setDataRequest({...dataRequest, all: false, lovedone: lovedone.id})
    setLovedoneSelected(lovedone)
    setReportCategory("Specific Lovedone")
  }

  const generateReport = async() => {
    if(!startDate || !endDate) {
      msgs.current.show({closable:false, severity: 'error', detail: 'All Fields Required to generate report' });
      return
    }

    setIsLoading(true)

    const response = await ReportService.getReport({
      ...dataRequest,
      startDate: moment(startDate).format('YYYY-MM-DD'),
      endDate: moment(endDate).format('YYYY-MM-DD')
    })

    if(response.status === 200) {
      setReport(response.data)
    } else {
      console.error(response)
    }

    setIsHiddenResult(false)
    setIsLoading(false)
  }

  return (
    <div className="page dashboard-page">
      {
        visible && dialog()
      }
      <span className="w-full" style={{maxWidth: '1200px'}}>
        <div className="text-600 font-bold text-2xl">Reports</div>
      </span>

      <div className="table-container table flex-column flex justify-content-center align-items-center">
        <Card className="shadow-2">

        <Messages ref={msgs} />

        <div className="p-fluid grid formgrid">

          <div className="field col-4 md:col-4">
            <label htmlFor="basic" className='text-600 font-medium'>Start date *</label>
            <Calendar
              id="basic"
              value={ startDate }
              onChange={(e) => setStartDate(e.value)}
              placeholder={"Start date"}
              showIcon
              iconPos='left'
            />
          </div>

          <div className="field col-4 md:col-4">
            <label htmlFor="basic" className='text-600 font-medium'>End date *</label>
            <Calendar
              id="basic"
              value={ endDate }
              onChange={(e) => setEndDate(e.value)}
              placeholder={"End date"}
              showIcon
              iconPos='left'
              maxDate={new Date()}
            />
          </div>
        </div>

        <label htmlFor="basic" className='text-600 font-medium mb-0'>Get by:</label>

        <div className='flex'>
          <div className="field-radiobutton field col-2">
            <RadioButton
              inputId="all lovedones"
              name="all lovedones"
              value="All lovedones"
              onChange={(e) => onAllLovedonesOptionSelected(e)}
              checked={reportCategory === 'All lovedones'}
            />
            <label htmlFor="all lovedones">All lovedones</label>
          </div>

          <div className="field-radiobutton field col-4">
            <RadioButton
              inputId="specific lovedone"
              name="Specific Lovedone"
              value="Specific Lovedone"
              onChange={(e) => onSpecificLovedoneOptionSelected(e)}
              checked={reportCategory === 'Specific Lovedone'}
            />
            <label htmlFor="specific lovedone">
              Specific Lovedone
            </label>
          </div>
        </div>

        <div className='text-center mb-5'>
          <Button
            label="Generate report"
            aria-label="Submit"
            onClick={()=> generateReport()}
            loading={isLoading}
          />
        </div>

        <hr />

        <div className='mt-5'>
          <div className='text-600 font-medium text-xl text-center mb-4'>
            Data For:
            <span className='text-color'>
              {
                reportCategory === "All lovedones"
                ? " All lovedones"
                :` ${lovedoneSelected?.firstName} ${lovedoneSelected?.lastName}`
              }
            </span>
          </div>

          <div className={`${isHiddenResult ? 'hidden' : 'flex'} justify-content-center text-center gap-6 scalein animation-duration-1000`}>
            <div style={{color:'#488ccb'}}>
              <div className='text-2xl  font-bold' >{report?.preventions}</div>
              <div>No. Preventions</div>
            </div>

            <div className='' style={{color: '#08b3cb'}}>
              <div className='text-2xl font-bold' >{report?.calls}</div>
              <div>No. Calls</div>
            </div>

            <div className='' style={{color: '#F58337'}}>
              <div className='text-2xl font-bold' >{report?.trips}</div>
              <div>No. Trips</div>
            </div>
          </div>
        </div>
        </Card>
      </div>
    </div>
  )
}

export default Reports
