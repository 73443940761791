import { useState, useEffect } from 'react';
import { Profile } from '../../models/profile.model';
import profileService from '../../services/profile.service';

import { Dialog } from 'primereact/dialog';

const UserInformationDialog = ({lovedOneId, isUserInformationVisible, setIsUserInformationVisible }:any) => {  
 
  const [profile, setProfile] = useState<Profile>();

  const getProfile = async () => {
    const response = await profileService.getProfile(lovedOneId);
    if (response?.status === 200) {      
      setProfile(response.data);
      
    } else {
      console.log('error getting feedback');
    }
  };

  useEffect(()=>{
    getProfile()
  },[])

  return (
    <Dialog 
      style={{ minWidth:'700px' }} 
      draggable={false} 
      header="User Information" 
      visible={isUserInformationVisible}       
      onHide={() => setIsUserInformationVisible(false)} 
      headerStyle={{ backgroundColor: '#488ccb', textAlign: 'center', color: '#ffffff' }}
    >
      <div className='text-center'>
        <div className="mb-4">
          <div className="text-700 font-bold text-lg">Annual Triggers</div>
          <span className="">{profile?.annualTriggers || <i>No info</i>}</span>
        </div>

        <div className="mb-4">
          <div className="text-700 font-bold text-lg">Biography</div>
          <span className="">{profile?.bio || <i>No info</i>}</span>
        </div>

        <div className="mb-4">
          <div className="text-700 font-bold text-lg">Hobbies</div>
          <span className="">{profile?.hobbies || <i>No info</i>}</span>
        </div>
      </div>
    </Dialog>
  )
}

export default UserInformationDialog;
