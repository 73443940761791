import { createSlice } from "@reduxjs/toolkit";
import { LoggedInStatus, UserSliceType } from "./types";
import LocalStorageService from '../../services/localStorage.service';
import { useLocation, useNavigate } from 'react-router-dom';
import { validatePrevLogin } from "./auth.actions";

const initialState: UserSliceType = {
    user: null,
    loginStatus: LoggedInStatus.NOT_VERIFIED
};

const authSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setLoggedInUser(state, action) {
            state.loginStatus = LoggedInStatus.LOGGED_IN;
            state.user = action.payload;
        },
        logoutUser(state) {
            state.loginStatus = LoggedInStatus.NOT_LOGGED_IN;
            state.user = null;
            LocalStorageService.setToken("");
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(validatePrevLogin.fulfilled, (state, action) => {
                state.loginStatus = LoggedInStatus.LOGGED_IN;
                state.user = action.payload;
            })
            .addCase(validatePrevLogin.rejected, (state, action) => {
                state.loginStatus = LoggedInStatus.NOT_LOGGED_IN
            })
    }
});

export const { setLoggedInUser, logoutUser } = authSlice.actions;
export default authSlice.reducer;