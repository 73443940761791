// PrimeReact imports
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import PrimeReact from 'primereact/api';

// theme
// download theme from: https://drive.google.com/file/d/1s1CXFi8C9TmRq58xCyrqqJT-sSVLkzLr/view?usp=share_link
import './theme/css/bootstrap.min.css';
import './theme/css/icons.min.css';
import './theme/css/app.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';

import './App.scss';

import { BrowserRouter } from 'react-router-dom';
import AppRouter from './components/router/app.router';

import { useDispatch } from 'react-redux';
import { validatePrevLogin } from './features/auth/auth.actions';
import { AppDispatch } from './features/store';
import { Toast } from 'primereact/toast';
import { useEffect, useRef } from 'react';
import useToast, { toastObservable } from './utils/hooks/showToast';

function App() {
  // primeReact configuration
  PrimeReact.ripple = true;
  const toastRef = useRef<any>();
  const {showToast} = useToast({ toastRef });


  const dispatch = useDispatch<AppDispatch>();
  dispatch(validatePrevLogin());

  // toast handler subscription
  useEffect(() => {
    const subscription = toastObservable.subscribe((toastData) => {
      showToast(toastData);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [showToast]);

  return (
    <BrowserRouter>
      <div id="layout-wrapper">
        <Toast ref={toastRef} />
        <AppRouter />
      </div>
    </BrowserRouter>
  );
}

export default App;
