import { useState, useEffect } from 'react';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';

import TimeLineDialog from '../dialogs/timeLineDialog';
import UserInformationDialog from '../dialogs/userInformationDialog';

import { Request } from '../../models/request.model';
import { Lovedone } from '../../models/lovedone.model'
import { RequestStatus } from '../../models/enums';

import './dashboardTable.scss';
import useDashboardActiveRequests from '../../utils/hooks/useDashboardActiveRequests';
import { useSelector } from 'react-redux';
import { RootState } from '../../features/store';
import { ChangedRequestEvent } from '../../features/activeRequests/types';

const DashboardTable = () => {
  // const [requests, setRequests] = useState<Request[]>([]);
  const [rowSelected, setRowSelected] = useState<Request>();
  const [isTimeLineVisible, setIsTimeLineVisible] = useState(false);
  const [isUserInformationVisible, setIsUserInformationVisible] = useState(false)
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState<any>(null);
  const [highlightedRows, setHighlightedRows] = useState<number[]>([]);

  const {requests, notificationSubject} = useSelector((state: RootState) => state.activeRequests);
  useDashboardActiveRequests();
  const highlightDurationSecs = 20;

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue('');
  };

  useEffect(() => {
    console.log("initialized master")
    initFilters();

    // subscribe to request changes
    const subscription = notificationSubject.subscribe((request: ChangedRequestEvent) => {
      console.log('request', request);
      setHighlightedRows([...highlightedRows, request.id]);
    });

    return () => {
      subscription.unsubscribe();
    }
  }, []);

  useEffect(() => {
    let timeoutId: any;

    if (highlightedRows.length) {
      timeoutId = setTimeout(() => {
        setHighlightedRows([]);
      }, highlightDurationSecs * 1000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [highlightedRows]);

  const rowClassName = (rowData: any, rowIndex: any) => {
    return highlightedRows.includes(rowData.id) ? 'highlighted' : '';
  };

  const onPressOptions = (data: Request) => {
    setRowSelected(data);
    setIsTimeLineVisible(true);
  };

  const onPressFullName = (data:Request) => {
    setRowSelected(data);
    setIsUserInformationVisible(true)
  }

  const toggleModal = () => {
    setIsTimeLineVisible(!isTimeLineVisible);
  };

  const status = ({ status }: Request) => {
    switch (status) {
      case RequestStatus.NONE:
        return <Badge style={{ backgroundColor: 'gray' }} value={RequestStatus[RequestStatus.NONE]} />;

      case RequestStatus.WAITING:
        return <Badge style={{ backgroundColor: '#F58337' }} value={RequestStatus[RequestStatus.WAITING]} />;

      case RequestStatus.ACCEPTED:
        return <Badge style={{ backgroundColor: '#08b3cb' }} value={RequestStatus[RequestStatus.ACCEPTED]} />;

      case RequestStatus.COMPLETED:
        return <Badge style={{ backgroundColor: '#0ead69' }} value={RequestStatus[RequestStatus.COMPLETED]} />;
    }
  };

  const onGlobalFilterChange1 = (e: any) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const clearFilter1 = () => {
    initFilters();
  };

  const titleTable = () => {
    return (
      <div className="flex justify-content-between">
        <div>Requests</div>
        <div>
          <Button icon="pi pi-filter-slash" label="Clear" className="p-button-text mr-1" onClick={clearFilter1} />
          <div className="p-input-icon-right">
            <i className="pi pi-search" />
            <InputText value={globalFilterValue} onChange={onGlobalFilterChange1} placeholder="Search" />
          </div>
        </div>
      </div>
    );
  };

  const timeLineDialog = () => {
    return <TimeLineDialog rowSelected={rowSelected} visible={isTimeLineVisible} toggleModal={toggleModal} />;
  };

  const idTemplate = (rowData: Request) => {
    return (
      <a
        className='underline' 
        style={{color: '#488ccb'}} 
        onClick={() => onPressOptions(rowData)}
      >
        {rowData?.id}
      </a>
    )
  }

  const fullNameTemplate = (rowData: Request) => { 
    return (
      <a 
        className='underline' 
        style={{color: '#488ccb'}} 
        onClick={() => onPressFullName(rowData)}
        >
          {rowData?.lovedone?.fullName} 
      </a>
    )
  }

  const actionBodyTemplate = (rowData: Request) => {
    return <Button onClick={() => onPressOptions(rowData)} icon="pi pi-chevron-circle-right" style={{ color: '#488ccb' }} className=" p-button-text p-button-lg p-button-rounded mr-2" />;
  };

  return (
    <div className="table-container flex-column flex justify-content-center align-items-center">
      <>
        {isTimeLineVisible && timeLineDialog()}

        {isUserInformationVisible 
          && <UserInformationDialog lovedOneId={rowSelected?.lovedone?.id} isUserInformationVisible={isUserInformationVisible} setIsUserInformationVisible={setIsUserInformationVisible}/>}

      <Card title={titleTable} className="shadow-2">
        <DataTable
          dataKey="id"
          className="table"
          value={requests}
          responsiveLayout="scroll"
          size="small"
          stripedRows
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          selectionMode="single"
          selection
          breakpoint="960px"
          sortField="id"
          sortOrder={-1}
          filters={filters}
          filterDisplay={'menu'}
          scrollable
          scrollHeight="600px"
          rowClassName={rowClassName}
        >
          <Column sortable style={{width: '80'}} body={idTemplate} field="id" header="Id"></Column>
          <Column sortable body={fullNameTemplate} field="lovedone.fullName" header="Full Name"></Column>
          <Column sortable field="lovedone.phone" header="Phone Number"></Column>
          <Column sortable field="createdAt" header="Request Date"></Column>
          <Column sortable field="status" body={status} header="Request Status"></Column>
          <Column sortable field="responder.fullName" header="Responder"></Column>
          <Column align={'center'} body={actionBodyTemplate} header="Options"></Column>
        </DataTable>
      </Card>
    </>
    </div>
  );
};

export default DashboardTable;
