import "./dashboardCard.scss";
import { FunctionComponent } from "react";
import { ProgressBar } from 'primereact/progressbar';

interface CardDashboardProps {
    icon: string;
    title: string;
    value: string;
    totalValue: string;
    color: string;
    footer: string | React.ReactNode;
}


const CardDashboard: FunctionComponent<CardDashboardProps> = (props) => {

    const getValue = () => {
        const operation = (parseInt(props.value) *100) / parseInt(props.totalValue )
        return operation.toFixed(0);
    }

    return (
        <div className="card-dashboard surface-0 shadow-2 p-3 border-1 border-50 border-round">


            <div className="flex justify-content-between mb-4">
                <div>
                    <span className="block text-600 font-medium mb-3">{props.title} </span>
                    {/* <div className="bg-primary min-w-full">
                        <span className="text-900 font-medium text-xl">{props.value}</span>
                        <ProgressBar value={80} showValue={false} ></ProgressBar>
                    </div> */}
                </div>
                <div className={"flex align-items-center justify-content-center border-round "} style={{ width: '2.5rem', height: '2.5rem', backgroundColor: `${props.color}` }}>
                    <i className={props.icon + " text-gray-100 text-xl"}></i>
                </div>
            </div>

            <div className="">
                <span className="w-2rem font-medium text-xl mr-4">
                    {props.value}/<b>{props.totalValue}</b>
                </span>

                <span>
                    <ProgressBar
                        color={`${props.color}`}
                        value={getValue()}
                        // showValue={false}
                        style={{width: '70%'}}
                        className="inline-block "
                    />
                </span>
            </div>
            {/* {props.footer} */}
        </div>
     );
}

export default CardDashboard;
