import api from "../utils/api";
import { Report } from '../models/report.model';
class ReportService {
  async getReport(data:Report) {
    return await api.post('/reports/usage/', data)
  }


}

export default new ReportService()