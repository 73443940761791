import React from 'react'
import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const DashboardSkeleton = () => {
  const products = Array.from({ length: 8 });

  const bodyTemplate = () => {
    return <Skeleton></Skeleton>
}
  return (
    <div className="p-4 bg-white shadow-1" style={{marginTop: '2rem', width: '1200px', border: '1px solid var(--surface-d)'}}>
      <div className='flex justify-content-between'>
        <Skeleton className='mb-4' width="10rem" height="2rem"></Skeleton>
        <Skeleton className='mb-4' width="14rem" height="2rem"></Skeleton>
      </div>

      <DataTable value={products}
        className="p-datatable-striped mt-2"
        breakpoint="950px">
        <Column style={{ width: '20%' }} body={bodyTemplate}></Column>
        <Column style={{ width: '20%' }} body={bodyTemplate}></Column>
        <Column style={{ width: '20%' }} body={bodyTemplate}></Column>
        <Column style={{ width: '20%' }} body={bodyTemplate}></Column>
        <Column style={{ width: '20%' }} body={bodyTemplate}></Column>
      </DataTable>
    </div>
  )
}
