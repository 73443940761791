import { IRoute } from "./models/route.model";
import { Navigate } from "react-router-dom";
import LoginPage from "./pages/auth/login/login";
import DashboardPage from "./pages/dashboard/dashboard";
import RegisterPage from "./pages/auth/register/register";
import Error404 from "./pages/errors/error404";
import HomePage from "./pages/home/homePage";
import PlaylistPage from "./pages/playlist/playlistPage";
import Administators from "./pages/administrators/administators";
import Reports from "./pages/reports/reports";
import Dashboard911 from "./pages/dashboard911/dashboard911";
import DeleteAccount from './pages/support/unsubscribe/deleteAccount';
import PrivacyPolicy from './pages/support/privacyPolicy/privacyPolicy';

export const routesConfig: IRoute[] = [
  { title: "Dashboard", path: "/", component: <DashboardPage /> },
  { title: "Dashboard", path: "/dashboard", component: <DashboardPage /> },
  {
    title: "Login",
    path: "/public/login",
    component: <LoginPage />,
    isPublic: true,
  },
  {
    title: "Delete Account",
    path: "/support/delete_account",
    component: <DeleteAccount />,
    isPublic: true,
  },
  {
    title: "Privacy Policy",
    path: "/public/privacy_policy",
    component: <PrivacyPolicy />,
    isPublic: true,
  },
  {
    title: "Register",
    path: "/public/register",
    component: <RegisterPage />,
    isPublic: true,
  },
  {
    title: "Administrators",
    path: "/administrators",
    component: <Administators />,
  },
  {
    title: "Reports",
    path: "/reports",
    component: <Reports />,
  },
  {
    title: "Dashboard911",
    path: "/dashboard911",
    component: <Dashboard911 />,
  },
  {
    title: "HomePage",
    path: "/home",
    component: <HomePage />,
    isPublic: false,
  },
  {
    title: "PlaylistPage",
    path: "/playlist",
    component: <PlaylistPage />,
  },
  {
    title: "Error404",
    path: "/404",
    component: <Error404 />,
    isPublic: true,
  },
  {
    title: "Error404",
    path: "*",
    component: <Navigate to="/404" replace={true} />,
    isPublic: true,
  },
];
