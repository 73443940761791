export enum SupportTicketStatus {
    PENDING = 0,
    IN_PROGRESS = 1,
    CLOSED = 2,
    CANCELLED = 3
}

export interface SupportTicket {
    title: string;
    description: string;
    userId?: number;
    status: SupportTicketStatus;
}