import { useState } from "react";
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";

import { Button } from "primereact/button";
import { AutoComplete } from "./AutoComplete";
import { Drawer } from "./Drawer";
import { Polygons } from "./Polygons";

import { SidebarRequestForm } from "../sidebar/sidebarRequestForm";
import { providers } from "../../utils/providers";

import greenPerson from "../../assets/images/greenPerson.png";
import bluePerson from "../../assets/images/bluePerson.png";
import redPhone from "../../assets/images/redPhone.png";
import "./map.scss";
import { Polygon } from '../../models/polygon';

const initialStateCoords = { lat: 44.899726, lng: -89.72133 };

const getIcon = (marker: any) => {
  switch (marker) {
    case "callEnded":
      return bluePerson;

    case "waitingCall":
      return bluePerson;

    case "onCall":
      return greenPerson;

    default:
      return;
  }
};

export const Map = () => {
  const [centerCoords, setCenterCoords] = useState<any>(initialStateCoords);
  const [zoom, setZoom] = useState<number>(10);
  const [markerCoords, setMarkerCoords] = useState();
  const [isSidebarVisible, setIsSidebarVisible] = useState<boolean>(false);
  const [selectedMarker, setSelectedMarker] = useState<any>("");
  const [polygon, setPolygon] = useState<Polygon>({name:'', path: []}); 
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);

  return (
    <div>      
      <GoogleMap
        center={centerCoords}
        zoom={zoom}
        mapContainerClassName="mapContainerStyle"
        options={{
          fullscreenControl: false,
        }}
      >
        <div className="autoCompleteContainer flex justify-content-around">
          
          <Button 
            className={`mr-4 p-button-rounded p-button-text surface-0 p-button-lg ${isDrawerVisible && 'bg-primary'}`}
            icon="pi pi-palette"
            tooltip={`${isDrawerVisible ? 'Disable Drawing': 'Enable Drawing'}`}
            tooltipOptions={{ position: 'left' }}
            onClick={() => setIsDrawerVisible(!isDrawerVisible)}
          />
          <div className="flex justify-content-around px-2 py-2 mr-3 surface-0 border-round-sm">
            <AutoComplete
              setMarkerCoords={setMarkerCoords}
              setCenterCoords={setCenterCoords}
            />
          </div>
          <Button
            className=" p-button-rounded p-button-text surface-0 p-button-lg "
            icon="pi pi-map-marker "
            tooltip="Center map"
            onClick={() => {
              setCenterCoords({ lat: 44.899726, lng: -89.72133 });
              setZoom(10);
            }}
          />
        </div>

        <SidebarRequestForm
          isSidebarVisible={isSidebarVisible}
          setIsSidebarVisible={setIsSidebarVisible}
        />

        {
          isDrawerVisible 
          && <Drawer polygon={polygon} setPolygon={setPolygon}/>
        }

        <Polygons markerCoords={markerCoords} polygon={polygon}/>

        <MarkerClusterer>
          {(clusterer) =>
            providers.map((provider: any) => (
              <Marker
                key={provider.name}
                position={provider.location}
                animation={google.maps.Animation.DROP}
                clusterer={clusterer}
                icon={getIcon(provider.status)}
                onClick={() => {
                  setSelectedMarker(provider);
                }}
              />
            ))
          }
        </MarkerClusterer>
      </GoogleMap>
      
      <Button
        className="mapContainerStyle__addRequest p-button-md"
        icon="pi pi-user"
        label="Request"
        onClick={() => setIsSidebarVisible(true)}
      />
    </div>
  );
};
