import { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpotify,
  faYoutube,
  faApple,
  faSoundcloud,
} from "@fortawesome/free-brands-svg-icons";
import "primeicons/primeicons.css";
import playlistService from "../../services/playlist.service";
import AddInfo from "./addInfo";
import { library } from "@fortawesome/fontawesome-svg-core";
import EditInfo from "./editInfo";
import { FilterMatchMode } from "primereact/api";
import { DashboardSkeleton } from "../skeleton/dashboardSkeleton";
import tidalIcon from "../../assets/icons/tidalIcon.svg";

const PlaylistTable = () => {
  library.add(faSpotify, faYoutube, faApple, faSoundcloud);
  let [playlist, setPlaylist] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleEdit, setIsVisibleEdit] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [value, setValue] = useState({ name: "", imgUrl: "" });
  const [comp, setComp] = useState<any>();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState<any>(null);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue("");
  };

  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChange = (e: any) => {
    const value = e.target.value;
    let _filters1 = { ...filters };
    _filters1["global"].value = value;

    setFilters(_filters1);
    setGlobalFilterValue(value);
  };

  const handleNewPlaylist = () => {
    handleTable();
    setValue({ name: "", imgUrl: "" });
  };

  const handleEditPlaylist = () => {
    handleTable();
    setIsVisibleEdit(false);
  };

  const handleTable = async () => {
    let resp = await playlistService.getAllPlaylists();
    if (resp.status === 200) {
      setPlaylist(resp.data);
    } else {
      console.log(resp.status, resp.data.error);
    }

    setIsLoading(false);
  };

  const handleRemove = async (id: any) => {
    setIsDeleting(true);
    let resp = await playlistService.removePlaylist(id);
    if (resp.status === 200) {
      handleTable();
    } else {
      console.log(resp.status, resp.data.error);
    }

    setIsDeleting(false);
  };

  const handleEdit = (id: any, playlist: any) => {
    setIsVisibleEdit(true);
    setComp(
      <EditInfo
        id={id}
        playlist={playlist}
        handleEditPlaylist={handleEditPlaylist}
      />
    );
  };

  useEffect(() => {
    handleTable();
    initFilters();
  }, []);

  const imageBody = (rowData: any) => {
    return (
      <>
        <img
          className="p-image	"
          src={rowData.imageUrl}
          style={{ width: "50%", height: 100, objectFit: "cover" }}
        />
      </>
    );
  };

  const serviceBody = (rowData: any) => {
    const getServiceIcon = (item: any) => {
      switch (item.service) {
        case 1:
          return (
            <>
              <FontAwesomeIcon
                icon={["fab", "spotify"]}
                style={{ fontSize: "1.8em", margin: 10, color: "#1ccc5b" }}
              />
            </>
          );

        case 2:
          return (
            <>
              <FontAwesomeIcon
                icon={["fab", "apple"]}
                style={{ fontSize: "1.8em", margin: 10 }}
              />
            </>
          );
        case 3:
          return (
            <>
              <FontAwesomeIcon
                icon={["fab", "soundcloud"]}
                style={{ fontSize: "1.8em", margin: 10, color: "#ff6c01" }}
              />
            </>
          );
        case 4:
          return (
            <>
              <FontAwesomeIcon
                icon={["fab", "youtube"]}
                style={{ fontSize: "1.8em", margin: 10, color: "red" }}
              />
            </>
          );
        case 5:
          return (
            <>
              <img src={tidalIcon} alt="tidal"></img>
            </>
          );
      }
    };

    return (
      <>
        {rowData?.services.map((item: any, index: number) => (
          <div key={index}>{getServiceIcon(item)}</div>
        ))}
      </>
    );
  };

  const buttonController = (rowData: any) => {
    return (
      <>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded mr-2 p-button-text"
          style={{ color: "#488ccb" }}
          onClick={() => handleEdit(rowData.id, playlist)}
        />
        <Button
          className="p-button-danger p-button-rounded p-button-text"
          icon="pi pi-times"
          onClick={() => {
            handleRemove(rowData.id);
          }}
        />
      </>
    );
  };

  const titleTable = () => {
    return (
      <div className="flex justify-content-between">
        <div>Playlist</div>

        <Button
          label="Add New "
          icon="pi pi-plus"
          className=""
          style={{ background: "#488ccb" }}
          onClick={() => setIsVisible(true)}
        />

        <div>
          <Button
            icon="pi pi-filter-slash"
            label="Clear"
            className="p-button-text mr-1"
            onClick={clearFilter}
          />
          <div className="p-input-icon-right">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {isLoading ? (
        <DashboardSkeleton />
      ) : (
        <Card
          title={titleTable}
          className="shadow-2"
          style={{ marginTop: "2rem" }}
        >
          <DataTable
            dataKey="id"
            className="table"
            value={playlist}
            responsiveLayout="scroll"
            size="small"
            stripedRows
            paginator
            rows={10}
            rowsPerPageOptions={[10, 20, 30]}
            selectionMode="single"
            selection
            breakpoint="960px"
            sortField="status"
            sortOrder={1}
            filters={filters}
            filterDisplay={"menu"}
            scrollable
            scrollHeight="600px"
            loading={isDeleting}
          >
            {/* <Column field="id" header="id"></Column> */}
            <Column
              sortable
              field="imageUrl"
              body={imageBody}
              header="Image"
            ></Column>

            <Column sortable field="name" header="Name"></Column>
            <Column
              sortable
              field="services"
              body={serviceBody}
              header="Available at"
            ></Column>
            <Column body={buttonController} header="Options"></Column>
          </DataTable>
        </Card>
      )}

      <Dialog
        visible={isVisible}
        onHide={() => setIsVisible(false)}
        draggable={false}
        header="Add a new playlist"
        headerStyle={{
          backgroundColor: "#488ccb",
          textAlign: "center",
          color: "#ffffff",
        }}
      >
        <div className="flex justify-content-between align-items-center mb-2">
          <label className="mr-5">Name</label>
          <InputText
            value={value.name}
            onChange={(e) => setValue({ ...value, name: e.target.value })}
          />
        </div>

        <div className="flex justify-content-between align-items-center">
          <label className="mr-5">Image URL</label>
          <InputText
            value={value.imgUrl}
            onChange={(e) => setValue({ ...value, imgUrl: e.target.value })}
          />
        </div>
        <Divider />

        <AddInfo
          value={value}
          setIsAddInfoVisible={setIsVisible}
          handleNewPlaylist={handleNewPlaylist}
        />
      </Dialog>

      <Dialog
        visible={isVisibleEdit}
        onHide={() => setIsVisibleEdit(false)}
        draggable={false}
        header="Edit"
        style={{ width: "80%" }}
        headerStyle={{
          backgroundColor: "#488ccb",
          textAlign: "center",
          color: "#ffffff",
        }}
      >
        {comp}
      </Dialog>
    </div>
  );
};

export default PlaylistTable;
