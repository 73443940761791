export const providers: any = [
  {
    name: "Stepth",
    // icon: redPhone,
    status: "callEnded",
    location: {
      lat: 45.06582757325606,
      lng: -90.04679997070312,
    },
  },
  {
    name: "Johny",
    // icon: greenPhone,
    status: "waitingCall",
    location: {
      lat: 45.05224670117951,
      lng: -89.33406193359374,
    },
  },
  {
    name: "Georgina",
    // icon: bluePhone,
    status: "onCall",
    location: {
      lat: 44.56412234200163,
      lng: -90.26103336914062,
    },
  },
  {
    name: "George",
    // icon: bluePhone,
    status: "onCall",
    location: {
      lat: 44.61693316857701,
      lng: -89.31208927734374,
    },
  },
  {
    name: "T1",
    // icon: bluePhone,
    status: "onCall",
    location: {
      lat: 46.82737885931932,
      lng: -92.64369328125,
    },
  },
  {
    name: "T2",
    // icon: redPhone,
    status: "callEnded",
    location: {
      lat: 46.20366153901527,
      lng: -92.852433515625,
    },
  },
  {
    name: "T3",
    // icon: greenPhone,
    status: "waitingCall",
    location: {
      lat: 47.14589509667721,
      lng: -92.22071964843751,
    },
  },
];
