import { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Messages } from 'primereact/messages';
import { useSelector, useDispatch } from "react-redux";
import { setLoggedInUser } from "../../../features/auth";
import { useLocation, useNavigate } from "react-router-dom";

import "./login.scss";
import logoBlue from "../../../assets/images/logo-blue.png";
import AuthService from "../../../services/auth.service";
import LocalStorageService from "../../../services/localStorage.service";

const LoginPage = () => {
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const msgs1 = useRef<any>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation() as any;
  const passwordRef = useRef<any>(null);

  const handleLogin = async () => {
    setIsLoading(true)

    // loading
    let resp = await AuthService.login(userName, password);
    if(!resp.data.success){
        msgs1.current.show({closable:false, severity: 'error', detail: 'Email or password invalid' });
    }

    if (resp.status === 200) {
      if (resp.data.success) {
        setError("");
        LocalStorageService.setToken(resp.data.token);
        dispatch(setLoggedInUser(resp.data.user));
        const nextPath = location.state?.from?.pathname || "/dashboard";
        navigate(nextPath, { replace: true });
      } else {
        setError(resp.data.message);
        setTimeout(() => {
          setError("");
        }, 3000);
      }
    }

    setIsLoading(false)
  };

  const handleEmailKeyPress = (e:any) => {
    if(e.key === "Enter"){
      if (password === ''){
        passwordRef.current.focus();
      }
      else {
        handleLogin();
      }
    }
  }

  const handleKeyPress = (e:any) => {
    if(e.key === "Enter")
      handleLogin();
  }

  return (
    <div className="page page__public login-page">

      <div className="flex align-items-center justify-content-center mt-5">
        <div className="surface-card p-4 shadow-2 border-round w-full md:w-6">
          <div className="text-center mb-4">
            <img src={logoBlue} alt="MERA" height={50} className="mb-3" />
            <div className="text-900 text-3xl font-medium mb-3">
              Welcome Back
            </div>

            <Messages ref={msgs1} />

          </div>

          <div>
            <label 
              htmlFor="email" 
              className="block text-900 font-medium mb-2"
            >
              Email
            </label>
            
            <InputText
              onBlur={(e) => setUserName(e.target.value)}
              id="email"
              type="text"
              className="w-full mb-3"
              onKeyPress={(e) => handleEmailKeyPress(e)}
            />

            <label
              htmlFor="password"
              className="block text-900 font-medium mb-2"
            >
              Password
            </label>
            <InputText
              onBlur={(e) => setPassword(e.target.value)}
              ref={passwordRef}
              id="password"
              type="password"
              className="w-full mb-3"
              onKeyPress={(e) => handleKeyPress(e)}
            />

            <div className="flex align-items-center justify-content-between mb-6">
              <a className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">
                Forgot your password?
              </a>
            </div>

            <Button
              disabled={!userName && !password}
              onClick={handleLogin}
              label={isLoading ? "" : "Sign In"}
              className="w-full"
              loading={isLoading}
              // icon="pi pi-user"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
