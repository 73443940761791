import { useSelector } from 'react-redux';
import { Routes, Route, useLocation, Navigate } from 'react-router-dom';
import { RootState } from '../../features/store';
import { routesConfig } from '../../routesConfig';
import Footer from '../footer/footer';
import NavBar from '../navBar/navBar';
import PrivateRoute from './privateRoute';

const AppRouter = () => {
  const location = useLocation();
  const user = useSelector((state: RootState) => state.auth.user);

  const getPath = () => {
    // remove the / at the end of the path
    if (location.pathname.endsWith('/')) {
      return location.pathname.slice(0, -1);
    }
  };

  const isPublic = !user || routesConfig.find((route) => route.path === getPath())?.isPublic;

  return (
    <>
      {!isPublic ? <NavBar /> : null}

      <div className="main-content">
        <div className={!isPublic ? 'page-content' : ''}>
          <div className="container-fluid p-0">
            <Routes>
              {/* Render public routes */}
              {routesConfig
                .filter((x) => x.isPublic)
                .map((r) => (
                  <Route key={r.title} path={r.path} element={r.component} />
                ))}

              {/* Render private routes */}
              {routesConfig
                .filter((x) => !x.isPublic)
                .map((r) => (
                  <Route key={r.title} path={r.path} element={<PrivateRoute component={r.component} />} />
                ))}
            </Routes>
          </div>
        </div>
      </div>

      {!isPublic ? <Footer /> : null}
    </>
  );
};

export default AppRouter;
