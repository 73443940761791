import { createAsyncThunk } from "@reduxjs/toolkit"
import authService from "../../services/auth.service"


export const validatePrevLogin = createAsyncThunk(
    // action type string
    'user/validate',
    // callback function
    async (_, thunkAPI) => {
        try {
            let resp = await authService.me();
            return resp.status === 200
                ? resp.data
                : thunkAPI.rejectWithValue(null);

        } catch (error: any) {
            return thunkAPI.rejectWithValue(null);
        }

    }
)
